import {inject, onBeforeMount, ref} from "vue";
import {useStore} from "vuex"
import {IApiResponse} from "@/types"

const login = ref('')
const login_top = ref('')
const login_top2x = ref('')
const loginMessage = ref('')
const loginTitle = ref('')
const msDomain = ref('')
const gapsDomain = ref('')
const demoDomain = ref(false)
const lastUpdated = ref('')
const companyURL = ref('')
const remember = ref(false)
const warning = ref('')
const alerts = ref({})
const customLogo = ref(false)
const providerRedirectUrl = ref('')
//const subdomain = ref('')

export const useLogin = ():any => {
  const store = useStore()

  onBeforeMount(() => {
    const isDebugMode = window['disarea']['isDebugMode']
    if (isDebugMode) {
      const params = new URLSearchParams(location.search)
      const serverParam = store.getters['dev/serverParam']
      if (serverParam) {
        params.delete("serverParam")
        params.append("serverParam", serverParam)
        const newParams = {}
        for (const [key, value] of params.entries()) {
          newParams[key] = value
        }
        console.log('onBeforeMount', params.toString())
        history.replaceState(newParams, params.toString(), "?"+params.toString())
      }
    }
  })

  const initLoginAppData = function () {
    console.log('initLoginAppData');
    const responseData = inject('loginPreloadData') as IApiResponse //response.data
    const xdata = responseData.xdata
    const redirectURL = xdata.redirect || responseData.redirect
    const redirectDelay = xdata.redirect_delay || responseData.redirect_delay || 0

    if (redirectURL) {
      setTimeout(() => { window.location.href = redirectURL }, redirectDelay)
      return
    }

    if (xdata.serverParam) {
      store.commit('dev/setServerParam', xdata.serverParam)
    }

    if (xdata.login) {
      login.value = xdata.login;
    }
    if (xdata.PROVIDER_REDIRECT_URL) {
      providerRedirectUrl.value = xdata.PROVIDER_REDIRECT_URL
    }
    // if (xdata.subdomain) {
    //   subdomain.value = xdata.subdomain
    // }
    msDomain.value = xdata.msDomain;
    gapsDomain.value = xdata.gapsDomain;
    demoDomain.value = xdata.demoDomain;
    remember.value = xdata.rm;
    if (xdata.lastUpdated) {
      lastUpdated.value = xdata.lastUpdated;
    }
    if (xdata.company_url) {
      companyURL.value = xdata.company_url;
    }
    if (xdata.customLogo) {
      customLogo.value = true
    }
    login_top.value = xdata.login_top
    login_top2x.value = xdata.login_top2x
    loginMessage.value = xdata.login_msg
    loginTitle.value = xdata.login_title
    warning.value = xdata.messages || ''
    //alerts.value = xdata.alerts || {};

    const alertType = responseData.isError ? 'error' : 'success'
    const alertMessage = responseData.message

    if (alertMessage) {
      alerts.value = [{type: alertType, text: alertMessage}]
    }
    store.commit('setConfig', xdata.config)

  }

  const clearAlerts = () => {
    alerts.value = []
  }

  return {
    login,
    login_top,
    login_top2x,
    loginMessage,
    loginTitle,
    msDomain,
    gapsDomain,
    demoDomain,
    lastUpdated,
    companyURL,
    customLogo,
    remember,
    warning,
    alerts,
    initLoginAppData,
    clearAlerts,
    providerRedirectUrl,
    //subdomain,
  }
}
