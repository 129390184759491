

  import { computed } from 'vue'
  import { useRoute } from 'vue-router'
  import LayoutMain from '@/layout/LayoutMain.vue'
  import LayoutLogin from '@/layout/LayoutLogin.vue'
  import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
  //import {useStore} from "vuex"
  import {useColorScheme} from "@/composables/useColorScheme"
  import {useStore} from "vuex"

  export default {

    components: {
      LayoutMain,
      LayoutLogin,
      SvgIcon,
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    setup() {
      const route = useRoute()
      const store = useStore()

      try {
        const isDebugMode = window['disarea']['isDebugMode']
        if (isDebugMode) {
          const serverParamMatches = window.location.search.match(/serverParam=([^&]+)/)
          if (serverParamMatches && serverParamMatches[1]) {
            store.commit('dev/setServerParam', serverParamMatches[1])
          }
        }
      } catch (e) {
        console.log('serverParam match error', e)
      }

      const {
        initLoginTheme
      } = useColorScheme()

      initLoginTheme()


      /*
            const themeLocalStorageValue = localStorage.getItem('global.interface.theme')
            const theme = ref(themeLocalStorageValue || store.getters.theme)

            watch(theme, () => {
              store.commit('changeTheme', theme.value)
              localStorage.setItem('global.interface.theme', theme.value)
              if (theme.value) {
                document.documentElement.setAttribute('data-theme', theme.value)
              } else {
                document.documentElement.removeAttribute('data-theme')
              }

            }, {immediate: true})
      */

      return {
        layoutName: computed(() => route.meta.layout)
      }
    }

  }
